import React from 'react';
import { ChipField,SingleFieldList,ReferenceManyField,ReferenceField ,
    SelectInput,ReferenceInput, RichTextField, Create, List, Datagrid, TextField, Edit, 
    SimpleForm, TextInput } from 'react-admin';


export const DeviceList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <RichTextField source="devicename" />   

            

            <TextField source="cycle_time" source="cycle_time"/>

            <TextField source="item_weight" source="item_weight"/>

            <TextField source="cavities_count" source="cavities_count"/>
           
            <RichTextField source="location" />
            
            <TextField source="id" />
          
            <TextField source="description" />

{/* First source is the lik to the foreign table which shoulf be the id , the second source is what we want to display in the view */}

<ReferenceField source="systemid" reference="Systems"><TextField source="system_name" /></ReferenceField>
            <ReferenceManyField label="Device's Controls" reference="controls" target="device_id">
                <SingleFieldList>
                    <ChipField source="control_name" />
                </SingleFieldList>
            </ReferenceManyField>
            <ReferenceManyField label="Device's Sensors" reference="sensors" target="device_id">
                <SingleFieldList>
                    <ChipField source="sensor_name" />
                </SingleFieldList>
            </ReferenceManyField>
        </Datagrid>
    </List>
  );

  export const DeviceEdit = props => (
    <Edit {...props}>
         <SimpleForm>

            <TextInput source="cycle_time" source="cycle_time"/>

            <TextInput source="item_weight" source="item_weight"/>

            <TextInput source="cavities_count" source="cavities_count"/>
            <TextInput source="location" />
            <ReferenceInput source="customer_id" reference="customer"><SelectInput optionText="id" /></ReferenceInput>
            <TextInput source="devicename" />
            <TextInput source="id" />
            <TextInput source="description" />
            <ReferenceInput source="systemid" reference="Systems"><SelectInput optionText="system_name" /></ReferenceInput>
            
        </SimpleForm>
    </Edit>
);

export const DeviceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="location" />
            <TextInput source="devicename" />
            <TextInput source="description" />
            <TextInput source="id" />
            <ReferenceInput source="customer_id" reference="customer"><SelectInput optionText="id" /></ReferenceInput>            
        </SimpleForm>
    </Create>
);