import React from 'react';
import './framestyle.css'


var cardStyle = {
    display: 'block',
    width: '100%',
    transitionDuration: '0.3s',
    height: '45vw'
}
const token = localStorage.getItem('access_token');

export default () => (
    
   
      <iframe src = {`https://prod-table.tarf.io/?token=${token}`} scrolling = "no" overflow = "hidden" width = "100%" height = "2000" frameborder = "0" allowfullscreen sandbox>

      
   
     
</iframe>
    
    
);