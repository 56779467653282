
import React from 'react';
import { FormDataConsumer,  NumberInput,SelectInput,ReferenceInput,Datagrid ,NumberField , ReferenceField, Create, List, TextField, Edit, SimpleForm, TextInput } from 'react-admin';

export const RulesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            
            <ReferenceField label="Sensing Device" source="device_id" reference="device"><TextField source="devicename" /></ReferenceField>
            <TextField source="parameter" />
            <TextField source="operator" />          
            
            <NumberField source="threshold" />
            <TextField source="action" />
            {/* <TextField label="Control to Ac(De)tivate" source="device_control" /> */}


            <ReferenceField label="Control to Ac(De)tivate" source="device_control" reference="controls"><TextField source="control_name" /></ReferenceField>
            <TextField source="alert_email" />

            
        </Datagrid>
    </List>
);


export const RuleEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput label="Sensing Device" source="device_id" reference="device"><SelectInput optionText="devicename" /></ReferenceInput>
            <ReferenceInput label="Parameter" source="parameter" reference="parameters"><SelectInput optionText="id" /></ReferenceInput>
            <ReferenceInput source="user_id" reference="customer"><SelectInput optionText="username" /></ReferenceInput>
            <ReferenceInput label="Operator" source="operator" reference="operators"><SelectInput optionText="operator" /></ReferenceInput>
            <NumberInput source="threshold" />
            <ReferenceInput label="Action" source="action" reference="actions">

                <SelectInput optionText="actionname" />
                {/* <SelectInput source="action" choices={[
                    { id: '1', name: 'Alert' },
                    { id: '2', name: 'Activate' },
                    { id: '3', name: 'Deactivate' },
                ]}/> */}

            </ReferenceInput>
            <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => (formData.action == 'Activate Control' || formData.action == 'Deactivate Control') &&
                    <ReferenceInput label="Control to Ac(De)tivate" source="device_control" reference="controls"><SelectInput optionText="control_name" /></ReferenceInput>
                }
            </FormDataConsumer>

            <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => (formData.action == 'Alert') &&
                    <TextInput label="Email" source="alert_email" type="email" />
                }
            </FormDataConsumer>

            {/* <ReferenceInput label="Control to Ac(De)tivate" source="device_control" reference="controls"><SelectInput optionText="control_name" /></ReferenceInput> */}
        </SimpleForm>
    </Edit>
);

export const RuleCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label="Sensing Device" source="device_id" reference="device"><SelectInput optionText="devicename"/></ReferenceInput>
            <ReferenceInput label="Parameter" source="parameter" reference="parameters"><SelectInput optionText="id" /></ReferenceInput>
            <ReferenceInput source="user_id" reference="customer"><SelectInput optionText="username" /></ReferenceInput>
            <ReferenceInput label="Operator" source="operator" reference="operators"><SelectInput optionText="operator" /></ReferenceInput>
            <NumberInput source="threshold" />
            <ReferenceInput label="Action" source="action" reference="actions">
              
                <SelectInput optionText="actionname" />
                {/* <SelectInput source="action" choices={[
                    { id: '1', name: 'Alert' },
                    { id: '2', name: 'Activate' },
                    { id: '3', name: 'Deactivate' },
                ]}/> */}
            
            </ReferenceInput>
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => (formData.actionname == "Activate") &&
                    <ReferenceInput label="Control to Ac(De)tivate" source="device_control" reference="controls"><SelectInput optionText="control_name" /></ReferenceInput>
                }
            </FormDataConsumer> */}

            <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => (formData.action == 'Activate Control' || formData.action == 'Deactivate Control')  &&
                    <ReferenceInput label="Control to Ac(De)tivate" source="device_control" reference="controls"><SelectInput optionText="control_name" /></ReferenceInput>
                }
            </FormDataConsumer>

            <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => (formData.action == 'Alert') &&
                    <TextInput label="Email" source="alert_email" type="email" />
                }
            </FormDataConsumer>

            {/* <ReferenceInput label="Control to Ac(De)tivate" source="device_control" reference="controls"><SelectInput optionText="control_name" /></ReferenceInput> */}
            
        </SimpleForm>

      
    </Create>
);

// console.log(props)

// formData.actionname == 'Activate Control'