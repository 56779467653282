import React from 'react';
import { Route } from 'react-router-dom';
// import explore from './pages/ExplorePage';
// import {Hello} from './pages/hello'
import dashboard from './dashboard';
import live_widgets from './live_widgets';
import TableReport from './TableReport';

export default [
    // <Route exact path="/hello" component={Hello} />,
    <Route exact path="/" component={dashboard} />,
    <Route exact path="/report" component={TableReport} />,
    <Route exact path="/widgets" component={live_widgets} />,
    
    
    
];




