import React from 'react';

import { MenuItemLink, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';

import DefaultIcon from '@material-ui/icons/ViewList';

import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';







const Menu1 = ({ onMenuClick, logout }) => {
   
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    // const open = useSelector(state => state.admin.ui.sidebarOpen);
    // const [open,setOpen] = React.useState(false);
    const [monitoropen, setMonitorOpen] = React.useState(true);
      const [adminopen, setAdminOpen] = React.useState(true);
      const [machinopen, setMachinOpen] = React.useState(true);

    const resources = useSelector(getResources);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            maxWidth: 1000,
            closedWidth:70,
            backgroundColor: theme.palette.background.paper,
            // paddingRight: theme.spacing(3),
        },
        nested: {
            paddingLeft: theme.spacing(5),
        },
    }));

    const classes = useStyles();
        
        

        const handleClick = () => {
            setAdminOpen(!adminopen);
        };

        const machinClick = () => {
            setMachinOpen(!machinopen);
        };

    const handleMonitor = () => {
      
        setMonitorOpen(!monitoropen);
        
    
    };

        return (
        <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        
        </ListSubheader>
                }

                className={classes.root}
            >
                

                <ListItem button onClick={handleMonitor} className={classes.root}>
                    <ListItemIcon>
                        <AssessmentIcon />
                    </ListItemIcon>
                <ListItemText primary="Monitoring" />
                    {monitoropen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={monitoropen} timeout="auto" unmountOnExit>
                    <List component="div" className={classes.nested}>

                        <MenuItemLink 
                            to={`/`}
                            primaryText={"Dashboard"}
                            leftIcon={<AssessmentIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={monitoropen} 
                            />

                            <MenuItemLink 
                            to={`/report`}
                            primaryText={"Report"}
                            leftIcon={<AssessmentIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={monitoropen} 
                            />

                           
                       
                    </List>


                </Collapse>

                <List component="div" >


                </List>
                

                <ListItem button onClick={handleClick} >
                    <ListItemIcon>
                        <SettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Admin"  />
                    {adminopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={adminopen} timeout="auto" unmountOnExit>
                
                <List component="div" className={classes.nested}>

                {
                    resources.map(resource => (resource.name.includes("alerts") || resource.name.includes("rules") ||  resource.name.includes("customer")) ?   
    
                        <MenuItemLink key={resource.name}
                            to={ `/${resource.name}`}
                            primaryText={(resource.options && resource.options.label) || resource.name}
                            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                            onClick={onMenuClick}
                            sidebarIsOpen={machinopen} />                                                             
                        : null
                            
    
                    )
    
                    
    
                }         
                    </List>
                                <ListItem button onClick={machinClick}>
                                <ListItemIcon>
                                    <SettingsIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Machine Config" />
                                {machinopen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>

                </Collapse>

            <Collapse in={adminopen} timeout="auto" unmountOnExit>

                                                    
       

                <Collapse in={machinopen} timeout="auto" unmountOnExit>
                    <List component="div" className={classes.nested}>

                                
                        
                        {
                            resources.map(resource => ((resource.name.includes("Systems") || resource.name.includes("device") || resource.name.includes("controls") || resource.name.includes("sensors") ) ?   

                                <MenuItemLink key={resource.name}
                                    to={ `/${resource.name}`}
                                    primaryText={(resource.options && resource.options.label) || resource.name}
                                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                                    onClick={onMenuClick}
                                    sidebarIsOpen={machinopen} />                                                             
                                : null
                                    

                            )

                            )

                        }                

                      

                    </List>

                    
                    </Collapse>

                    </Collapse>


            </List>
        );
    
}
export default (Menu1);



// <MenuItemLink

// to={`/widgets`}
// primaryText={"Live Guages"}
// leftIcon={<NetworkCheckIcon />}
// onClick={onMenuClick}
// sidebarIsOpen={monitoropen} 

// />