import React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import hasuraDataProvider from 'ra-data-hasura';
import { DeviceList, DeviceEdit, DeviceCreate } from './components/devices'
import { ControlList, ControlEdit, ControlCreate } from './components/controls'
import { SensorList, SensorEdit, SensorCreate } from './components/sensors'
// import { aquacontrolList } from './components/aquaponic_controls_data'
import { SystemList, SystemEditList, SystemCreate } from './components/systems'
import { RulesList, RuleCreate, RuleEdit } from './components/Rules'
import { AlertList } from './components/alerts'
// import { ActionsList} from './components/Actions'
// import { Customer_controlList } from './components/controlpanel'
import authProvider from './authProvider';
import {createMuiTheme} from '@material-ui/core/styles';
import Dashboard from './components/dashboard'
import CustomRoutes from './components/CustomRoutes'
import MyLayout from './layout';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import RouterIcon from '@material-ui/icons/Router';
import PersonIcon from '@material-ui/icons/Person';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import WavesIcon from '@material-ui/icons/Waves';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
import GavelIcon from '@material-ui/icons/Gavel';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import AirplayIcon from '@material-ui/icons/Airplay';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import TableReport from './components/TableReport'
// import LoginPage from "./components/pages/Loginpage";
// import Login from "./components/pages/Login"
// Nimport { ApolloProvider } from "react-apollo";
// import { ApolloClient } from './components/graphql/client'
// import ExplorePage from './components/pages/ExplorePage'


const token = localStorage.getItem('access_token');

console.log("access token from main app", token)

const headers = { 'content-type': 'application/json', 'authorization': `Bearer ${token}` };


const dataProvider = hasuraDataProvider('https://sanam-hasura.tarf.io', headers);

const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
   
  },
   sidebar: {
     width: 200, // The default value is 240
     closedWidth: 50, // The default value is 55
   }
});

// console.log(headers);
//loginPage={LoginPage}
const App = () => (
  <div>
    < Admin theme = {theme}
      customRoutes={CustomRoutes}
      dashboard={Dashboard}
      report={TableReport}
      layout = {MyLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
     
      
      >

      <Resource name="alerts" options={{ label: 'Alerts' }} list={AlertList}  icon={DeviceHubIcon} />

      <Resource name="rules" options={{ label: 'Rules' }} list={RulesList} edit={RuleEdit} create={RuleCreate} show={ShowGuesser} icon={GavelIcon} />

      <Resource name="Systems" options={{ label: 'Machines' }} list={SystemList} edit={SystemEditList} create={SystemCreate} show={ShowGuesser} icon={AirplayIcon} />

      <Resource name="device" options={{ label: 'Devices' }} list={DeviceList} edit={DeviceEdit} create={DeviceCreate} icon={RouterIcon}/>
   
      <Resource name="customer" options={{ label: 'User Info' }} list={ListGuesser} edit={EditGuesser} show={ShowGuesser} icon={PersonIcon}/>

      <Resource name="controls" options={{ label: 'Controls' }} list={ControlList} edit={ControlEdit} create={ControlCreate} icon={KeyboardReturnIcon}/>

      <Resource name="sensors" options={{ label: 'Sensors' }} list={SensorList} edit={SensorEdit} create={SensorCreate} icon={NetworkCheckIcon}/>

      

     

     

         
      {/* <Resource name="aquaponic_controls_data" options={{ label: 'Aquaponics' }} list={aquacontrolList} edit={EditGuesser} show={ShowGuesser} icon={WavesIcon}/> */}
  
      <Resource name="actions"/>
      <Resource name="operators" />
      <Resource name="parameters" /> 
      {/* <Resource name="controls" />  */}
      

    </Admin>
    
  </div>
);

export default App;

//// <Resource name="alerts" options={{ label: 'Alerts' }} list={AlertList}  icon={DeviceHubIcon} icon={AddAlertIcon}/>

//////////////  <Resource name="rules" options={{ label: 'Rules' }} list={RulesList} edit={RuleEdit} create={RuleCreate} show={ShowGuesser} icon={GavelIcon} />