// in src/MyLayout.js
import React from "react";
import {Layout} from 'react-admin';
// import MySidebar from './components/MySidebar';
import MyMenu from './components/test-menu3';



const MyLayout = (props) => < Layout {...props} menu = {MyMenu}/>;

export default MyLayout;



