import React from 'react';

import './framestyle.css'


// var cardStyle = {
//     display: 'block',
//     width: '100%',
//     transitionDuration: '0.3s',
//     height: '45vw'
// }
const token = localStorage.getItem('access_token');

export default () => (
    
    



    <iframe src={`http://95.216.227.58:30450/?token=${token}`} scrolling="no" overflow="hidden" width="100%" height="2000" frameBorder="0" allowFullScreen sandbox="allow-same-origin allow-scripts allow-popups allow-forms">

    </iframe>

  


    
    
);

//  <iframe src={`http://localhost:8000/?token=${token}`} scrolling="no" overflow="hidden" width="100%" height="2000" frameborder="0" allowfullscreen sandbox> 

 // <iframe src={`http://95.216.227.58:30494/?token=${token}`} scrolling="no" overflow="hidden" width="100%" height="2000" frameBorder="0" allowFullScreen sandbox="allow-same-origin allow-scripts allow-popups allow-forms">