import React from 'react';
import {  List, Datagrid, TextField} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

export const AlertList = props => (
   
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="deviceid" />
            {/* <TextField source="systemid" /> */}
            <TextField source="alerttext" />
        </Datagrid>
    </List>

  );

 