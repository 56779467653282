import React from 'react';
import './framestyle.css'


var cardStyle = {
    display: 'block',
    width: '100%',
    transitionDuration: '0.3s',
    height: '45vw'
}
const token = localStorage.getItem('access_token');

export default () => (
    
  


    //   <iframe src={`http://localhost:3006/?token=${token}`} scrolling="no" overflow="hidden" width="100%" height="2000" frameborder="0" allowfullscreen sandbox> 
   
      <iframe src = {`https://production-dashboard.tarf.io/?token=${token}`} scrolling = "no" overflow = "hidden" width = "100%" height = "2000" frameborder = "0" allowfullscreen sandbox>
   
     
</iframe>
    
    
);


// <iframe src = {`http://95.216.227.58:31554/?token=${token}`} scrolling = "no" overflow = "hidden" width = "100%" height = "2000" frameborder = "0" allowfullscreen sandbox>


// <iframe src={`http://localhost:5000/?token=${token}`} scrolling="no" overflow="hidden" width="100%" height="2000" frameborder="0" allowfullscreen sandbox>   